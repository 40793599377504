import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';

import css from './styles/Nav.module.css';
import DecoratedText from '../components/DecoratedText';

export default function Nav() {
    const matchesDesktop = useMediaQuery('(min-width: 800px)');
    const mobileMenu = useRef(null);
    const { pathname } = useLocation();

    const homePath = pathname.startsWith('/en') ? 'en' : pathname.startsWith('/es') ? 'es' : ''; 

    const handleMobileNavClose = () => mobileMenu.current.style.top = null;

    const toggleMobileNav = () => {
        if (mobileMenu.current.style.top) return handleMobileNavClose();
        mobileMenu.current.style.top = 0;
    };

    return (
        <>
            { matchesDesktop ? (
                <nav className={css.wrapper} data-testid='nav-desktop'>
                    <Link to={`/${homePath}`} data-testid='nav-home'><DecoratedText className={css.logo}>Emilio Popovits Blake</DecoratedText></Link>
                    <div className={css.linkContainer}>
                        <Link to='work' className={css.links} data-testid='nav-work'><DecoratedText><FormattedMessage id='nav-work'/></DecoratedText></Link>
                        <Link to='projects' className={css.links} data-testid='nav-projects'><DecoratedText><FormattedMessage id='nav-projects'/></DecoratedText></Link>
                        <a href='https://github.com/empobla' target='_blank' className={css.links} rel='noreferrer' data-testid='nav-github'><DecoratedText>Github</DecoratedText></a>
                        <Link to='contact' className={css.links} data-testid='nav-contact'><DecoratedText><FormattedMessage id='nav-contact'/></DecoratedText></Link>
                    </div>
                </nav>
            ) : (
                <>
                    <nav className={css.wrapperMobile} onClick={toggleMobileNav} data-testid='nav-mobile'>
                        <DecoratedText className={css.logo}>Emilio Popovits Blake</DecoratedText>
                        <SortIcon sx={{ position: 'absolute', left: '0.5em' }} />
                    </nav>
                    <div className={css.menuWrapper} ref={mobileMenu} data-testid='nav-mobile-menu'>
                        <div className={css.menu}>
                            <Link to={`/${homePath}`} className={css.links} onClick={handleMobileNavClose} data-testid='nav-home'><DecoratedText><FormattedMessage id='nav-home'/></DecoratedText></Link>
                            <Link to='work' className={css.links} onClick={handleMobileNavClose} data-testid='nav-work'><DecoratedText><FormattedMessage id='nav-work'/></DecoratedText></Link>
                            <Link to='projects' className={css.links} onClick={handleMobileNavClose} data-testid='nav-projects'><DecoratedText><FormattedMessage id='nav-projects'/></DecoratedText></Link>
                            <a href='https://github.com/empobla' target='_blank' onClick={handleMobileNavClose} className={css.links} rel='noreferrer' data-testid='nav-github'><DecoratedText>Github</DecoratedText></a>
                            <Link to='contact' className={css.links} onClick={handleMobileNavClose} data-testid='nav-contact'><DecoratedText><FormattedMessage id='nav-contact'/></DecoratedText></Link>
                            <CloseIcon 
                                sx={{ 
                                    color: '#fff', 
                                    bgcolor: '#313539', 
                                    padding: 1, 
                                    borderRadius: '50%',
                                    mt: 3
                                }} 
                                onClick={handleMobileNavClose}
                                data-testid='nav-close'
                            />
                        </div>
                    </div>
                </>
            ) }
        </>
    );
};