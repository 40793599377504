import css from './styles/DecoratedText.module.css';

export default function DecoratedText({ children, ...props }) {
    return (
        <p {...props} data-testid='decorated-text'>
            <span className={css.decorators}>{'<'}</span>
            <span>{children}</span>
            <span className={css.decorators}>{' />'}</span>
        </p>
    );
};