import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import css from './styles/Footer.module.css';
import DecoratedText from '../components/DecoratedText';
import useLocale from '../hooks/useLocale';

export default function Footer() {
    const { pathname } = useLocation();
    const locale = useLocale();
    const intl = useIntl();

    const thisYear = (new Date(Date.now())).getFullYear();
    const enLangLink = `/en${pathname.startsWith('/en') ? pathname.split('/en')[1] : pathname.startsWith('/es') ? pathname.split('/es')[1] : pathname}`;
    const esLangLink = `/es${pathname.startsWith('/en') ? pathname.split('/en')[1] : pathname.startsWith('/es') ? pathname.split('/es')[1] : pathname}`;

    return (
        <footer className={css.wrapper}>
            <div className={css.footerText}>
                <p className={css.copyright} data-testid='footer-rights'>&#169; {thisYear} Emilio Popovits Blake. {intl.formatMessage({ id: 'footer-rights' })}</p>
                <div className={css.languages}>
                    <Link to={enLangLink} className={css.english} data-testid='footer-english'>
                        { locale === 'en' 
                            ? <DecoratedText>English</DecoratedText>
                            : <p>English</p>
                        }
                    </Link>
                    <Link to={esLangLink} data-testid='footer-spanish'>
                        { locale === 'es' 
                            ? <DecoratedText>Español</DecoratedText>
                            : <p>Español</p>
                        }
                    </Link>
                </div>
            </div>
        </footer>
    );
};