import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import css from './styles/Home.module.css';
import { getProjectCount, getRandomFeatured } from '../lib/sanity';
import DecoratedText from '../components/DecoratedText';
import ANMLogo from '../assets/images/anm.webp';
import MedalliaLogo from '../assets/images/medallia.webp';
import NextGSysLogo from '../assets/images/nextgsys.webp';
import useLocale from '../hooks/useLocale';
import Laptop from '../components/Laptop';

const startDate = new Date('07/01/2019');
const getYearsDifference = start => Math.abs((new Date(Date.now() - start)).getUTCFullYear() - 1970);

export default function Home() {
    const locale = useLocale();
    const intl = useIntl();
    const { featured, successfulProjects } = useLoaderData();
    const { pathname } = useLocation();
    const isMobile = useMediaQuery('(hover: none)');

    const featuredStart = pathname.startsWith('/en') ? '/en' : pathname.startsWith('/es') ? '/es' : '';

    const handleIntersectionChange = (inView, entry) => {
        if (inView) entry.target.classList.add(css.animation);
    };

    const handleLaptopIntersection = (inView, entry) => {
        if (!inView) return;
        entry.target.classList.add(css.animation);
        entry.target.addEventListener('animationend', () => {
            entry.target.classList.remove(css.animation);
            entry.target.style.opacity = 1;
        }, { once: true });
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{intl.formatMessage({ id: 'home-title' })}</title>
                <meta property='og:title' content={intl.formatMessage({ id: 'home-title' })} />
                <meta name='description' content={intl.formatMessage({ id: 'home-description' })} />
                <meta property='og:description' content={intl.formatMessage({ id: 'home-description' })} />
            </Helmet>

            <div className={css.wrapper}>
                <div className={css.banner}>
                    <h1 className={`${css.header} ${css.animation}`} data-testid='home-header'>
                        { (!locale || locale === 'en') && <>I'm <span>Emilio</span>, a <span>Computer Scientist</span> and a "First World Problem" <span>Solver</span></> }
                        { locale === 'es' && <>Soy <span>Emilio</span>, un <span>Ingeniero en Computación</span> y un <span>solucionador</span> de problemas de "Primer Mundo"</> }
                    </h1>
                </div>
                <div className={css.about}>
                    <div className={css.aboutContainer}>
                        <DecoratedText className={`${css.subheader} ${css.animation}`}><FormattedMessage id='home-subheader' /></DecoratedText>
                        <p className={`${css.sidetext} ${css.animation}`} data-testid='home-abouttext'>
                            <FormattedMessage id='home-about' />
                        </p>
                        <div className={`${css.stats} ${css.animation}`} data-testid='home-stats'>
                            <div className={css.stat}>
                                <p className={css.statNumber}>{ getYearsDifference(startDate) }<span>+</span></p>
                                <p className={css.statDesc} data-testid='home-years'><FormattedMessage id='home-years' /></p>
                            </div>
                            <div className={css.stat}>
                                <p className={css.statNumber}>{successfulProjects}<span>+</span></p>
                                <p className={css.statDesc} data-testid='home-success'><FormattedMessage id='home-success' /></p>
                            </div>
                        </div>
                        <div className={`${css.networks} ${css.animation}`}>
                            <DecoratedText className={css.subheader}><FormattedMessage id='home-follow' /></DecoratedText>
                            <div className={css.networksIcons}>
                                <a href='https://github.com/empobla' target='_blank' rel='noreferrer' data-testid='home-githublink'><GitHubIcon sx={{ fontSize: '2.5em', marginRight: '1em' }} /></a>
                                <a href='https://www.linkedin.com/in/emilio-popovits/' target='_blank' rel='noreferrer' data-testid='home-linkedinlink'><LinkedInIcon sx={{ fontSize: '2.5em' }} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.workWrapper}>
                <InView as='div' className={css.workLogos} data-testid='home-worklogos' onChange={handleIntersectionChange} triggerOnce>
                    <LazyLoadImage className={css.workLogo} src={ANMLogo} alt='ANM Logo' data-testid='home-anmlogo' />
                    <LazyLoadImage className={css.workLogo} src={MedalliaLogo} alt='Medallia Logo' data-testid='home-medallialogo' />
                    <LazyLoadImage className={css.workLogo} src={NextGSysLogo} alt='NextGSys Logo' data-testid='home-nextgsyslogo' />
                </InView>
            </div>
            { featured.length > 0 && 
                <div className={css.featuredWrapper} data-testid='home-featuredwrapper'>
                    <InView as='div' className={css.featuredGridWrapper} onChange={handleIntersectionChange} triggerOnce threshold={!isMobile ? 0.6 : 0}>
                        <div>
                            <h2 className={css.featuredHeader} data-testid='home-featuredheader'><DecoratedText><FormattedMessage id='home-featured' /></DecoratedText></h2>
                            <p className={css.featuredText} data-testid='home-featuredtext'><FormattedMessage id='home-featuredtext' /></p>
                        </div>
                    </InView>
                    { featured.map((project, idx) => (
                        <InView as={Link} to={`${featuredStart}/portfolio/${project.slug}`} key={idx} className={css.featuredScreen} id={css[`featured${idx+1}`]} data-testid='home-featuredlaptop' onChange={handleLaptopIntersection} triggerOnce threshold={!isMobile ? 0.6 : 0}>
                            <Laptop screens={project.screens} />
                        </InView>
                    )) }
                    <InView as='div' className={css.projectsTextGridWrapper} onChange={handleIntersectionChange} triggerOnce threshold={!isMobile ? 0.6 : 0}>
                        <div>
                            <h2 className={css.featuredHeader} data-testid='home-learnmoreheader'><DecoratedText><FormattedMessage id='home-learnmore' /></DecoratedText></h2>
                            <p className={css.featuredText} data-testid='home-learnmoretext'><FormattedMessage id='home-learnmoretext' /></p>
                        </div>
                    </InView>
                </div>
            }
        </>
    );
};

export async function homeLoader() {
    try{
        const [featured, successfulProjects] = await Promise.all([
            getRandomFeatured(), getProjectCount()
        ]);
        return { featured, successfulProjects };
    } catch (error) {
        throw new Response(JSON.stringify({ location: 'homeLoader', error }), { status: 500, statusText: 'Internal Server Error' });
    }
};