import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import css from './styles/Work.module.css';
import { getOtherProjects, getProjects } from '../lib/sanity';
import { getLocale } from '../hooks/useLocale';
import DecoratedText from '../components/DecoratedText';
import Card from '../components/Card';
import MiniCard from '../components/MiniCard';
import { InView } from 'react-intersection-observer';

export default function Projects() {
    const projects = useLoaderData();
    const intl = useIntl();

    const handleIntersectionChange = (inView, entry) => {
        if (inView) entry.target.classList.add(css.animation);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{intl.formatMessage({ id: 'projects-title' })}</title>
                <meta property='og:title' content={intl.formatMessage({ id: 'projects-title' })} />
                <meta name='description' content={intl.formatMessage({ id: 'projects-description' })} />
                <meta property='og:description' content={intl.formatMessage({ id: 'projects-description' })} />
            </Helmet>

            <div className={css.wrapper}>
                <h1 className={`${css.header} ${css.animation}`} data-testid='projects-header'><DecoratedText><FormattedMessage id='projects-header'/></DecoratedText></h1>
                <div className={`${css.cardContainer} ${css.animation}`} data-testid='projects-cards'>
                    { projects.main.map((project, idx) => (
                        <Card key={idx} data={project} />
                    )) }
                </div>
                <InView as='div' className={css.miniCardContainer} data-testid='projects-minicards' onChange={handleIntersectionChange} triggerOnce>
                    { projects.other.map((project, idx) => (
                        <MiniCard key={idx} data={project} />
                    )) }
                </InView>
            </div>
        </>
    );
};

export async function projectsLoader({ request: { url } }) {
    try {
        const locale = getLocale(url);
        const data = await Promise.all([
            getProjects('project', locale),
            getOtherProjects('project', locale)
        ]);
        const projects = { main: data[0], other: data[1] };
        return projects;
    } catch(error) {
        throw new Response(JSON.stringify({ location: 'projectsLoader', error }), { status: 500, statusText: 'Internal Server Error' });
    }
};