import { useEffect, useState } from 'react';

const preloadImage = src => new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = image.onabort = () => reject(src);
    image.src = src;
});

export default function useImagePreloader(imageList = []) {
    const [imagesPreloaded, setImagesPreloaded] = useState(false);

    useEffect(_ => {
        let isCancelled = false;

        const effect = async () => {
            if (isCancelled) return;

            const imagePromises = imageList.map(image => preloadImage(image));
            await Promise.all(imagePromises);

            if (isCancelled) return;
            setImagesPreloaded(true);
        };

        effect();
        return () => { isCancelled = true; };
    }, [imageList]);

    return imagesPreloaded;
};