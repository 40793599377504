import { Carousel } from 'react-responsive-carousel';

import css from './styles/Mobile.module.css';
import MobileImg from '../assets/images/mobile.webp';
import LazyLoadCarouselImage from './LazyLoadCarouselImage';
import MobileLoadingGIF from '../assets/images/mobileLoading.gif'

export default function Mobile({ screens, id }) {
    return (
        <div className={css.mobileContainer} data-testid='mobile' id={id}>
            <img className={css.mobile} src={MobileImg} alt='Mobile' />
            <div className={css.mobileScreen}>
                <Carousel 
                    showThumbs={false} 
                    showIndicators={false} 
                    showStatus={false} 
                    showArrows={false}
                    autoPlay
                    infiniteLoop
                >
                    { screens.map((screen, idx) => (
                        <LazyLoadCarouselImage key={idx} src={screen} loading={MobileLoadingGIF} data-testid='mobile-screen' />
                    )) }
                </Carousel>
            </div>
        </div>
    );
};