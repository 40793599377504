import { Link, useLocation } from 'react-router-dom';
import { Chip, Stack, useMediaQuery } from '@mui/material';
import { toPlainText } from '@portabletext/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import css from './styles/Card.module.css';
import DecoratedText from './DecoratedText';

export default function Card({ data, id }) {
    const { pathname } = useLocation();
    const matchesTablet = useMediaQuery('(min-width: 750px) and (orientation: portrait)');
    const matchesDesktop = useMediaQuery('(min-width: 800px)');

    const projectLink = `${pathname.startsWith('/en') ? '/en' : pathname.startsWith('/es') ? '/es' : ''}/portfolio/${data.slug}`;

    return (
        <Link to={projectLink} className={css.card} style={{ backgroundColor: data.thumbnail.bg }} data-testid='card-wrapper' id={id}>
            <div className={css.content}>
                <div className={css.filter} />
                <div className={css.header}>
                    <DecoratedText className={css.title}>{data.name}</DecoratedText>
                    <Stack direction='row' spacing={1} data-testid='card-chips'>
                        { data.tags.map((tag, idx) => (
                            <Chip 
                                key={idx} 
                                sx={{ 
                                    color: '#fff', 
                                    bgcolor: '#6a687099',
                                    fontSize: matchesTablet ? '0.8em' : null
                                }} 
                                label={tag} 
                            />
                        )) }
                    </Stack>
                </div>
                <div className={css.body}>
                    <img 
                        className={css.img} 
                        src={data.thumbnail.url} 
                        alt={data.name} 
                    />
                </div>
            </div>
            { matchesDesktop && 
                <div className={`${css.content} ${css.overlay}`} hidden data-testid='card-description'>
                    <div className={css.overlayContainer}>
                        <LazyLoadImage className={css.logo} src={data.logo} alt={`${data.name} Logo`} />
                        <p className={css.description}>
                            {toPlainText(data.shortDesc)}
                        </p>
                    </div>
                </div>
            }
        </Link>
    );
};