import { useEffect, useState } from 'react';
import { Chip, useMediaQuery } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { useLocation } from 'react-router-dom';

import css from './styles/MiniCard.module.css';
import DecoratedText from './DecoratedText';
import Modal from './Modal';

export default function MiniCard({ data }) {
    const matchesTablet = useMediaQuery('(min-width: 750px) and (orientation: portrait)');
    const { hash } = useLocation();

    const [visible, setVisible] = useState(false);

    useEffect(_ => {
        if (hash.replace('#', '') === data.slug) handleOpenModal();
    }, [hash, data.slug]);

    const handleOpenModal = () => setVisible(true);
    const handleCloseModal = () => setVisible(false);

    return(
        <>
            <div className={css.miniCard} onClick={handleOpenModal} id={data.slug} data-testid='minicard-wrapper'>
                <DecoratedText className={css.header}>{data.name}</DecoratedText>
                <div className={css.tags} data-testid='minicard-tags'>
                    { data.tags.map((tag, idx) => (
                        <Chip
                            key={idx} 
                            sx={{ 
                                color: '#fff', 
                                bgcolor: '#6a687099',
                                fontSize: matchesTablet ? '0.6em' : null,
                                mt: '0.5em'
                            }} 
                            label={tag} 
                        />
                    )) }
                </div>
                <div className={css.description} data-testid='minicard-description'>
                    <PortableText value={data.description.short} />
                </div>
            </div>
            <Modal visible={visible} onClose={handleCloseModal} data={data} />
        </>
    );
};