import { Carousel } from 'react-responsive-carousel';

import css from './styles/Laptop.module.css';
import DesktopImg from '../assets/images/desktop.webp';
import LazyLoadCarouselImage from './LazyLoadCarouselImage';
import DesktopLoadingGIF from '../assets/images/desktopLoading.gif'

export default function Laptop({ screens, id }) {
    return (
        <div className={css.desktopContainer} data-testid='laptop' id={id}>
            <img className={css.desktop} src={DesktopImg} alt='Laptop' />
            <div className={css.desktopScreen}>
                <Carousel 
                    showThumbs={false} 
                    showIndicators={false} 
                    showStatus={false} 
                    showArrows={false}
                    autoPlay
                    infiniteLoop
                    stopOnHover
                >
                    { screens.map((screen, idx) => (
                        <LazyLoadCarouselImage  key={idx} src={screen} loading={DesktopLoadingGIF} data-testid='laptop-screen'/>
                    )) }
                </Carousel>
            </div>
        </div>
    );
};