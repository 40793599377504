import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { InView } from 'react-intersection-observer';

import css from  './styles/Work.module.css';
import { getOtherProjects, getProjects } from '../lib/sanity';
import { getLocale } from '../hooks/useLocale';
import DecoratedText from '../components/DecoratedText';
import Card from '../components/Card';
import MiniCard from '../components/MiniCard';

export default function Work() {
    const work = useLoaderData();
    const intl = useIntl();

    const handleIntersectionChange = (inView, entry) => {
        if (inView) entry.target.classList.add(css.animation);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{intl.formatMessage({ id: 'work-title' })}</title>
                <meta property='og:title' content={intl.formatMessage({ id: 'work-title' })} />
                <meta name='description' content={intl.formatMessage({ id: 'work-description' })} />
                <meta property='og:description' content={intl.formatMessage({ id: 'work-description' })} />
            </Helmet>

            <div className={css.wrapper}>
                <h1 className={`${css.header} ${css.animation}`} data-testid='work-header'><DecoratedText><FormattedMessage id='work-header' /></DecoratedText></h1>
                <div className={`${css.cardContainer} ${css.animation}`} data-testid='work-cards'>
                    { work.main.map((project, idx) => (
                        <Card key={idx} data={project} />
                    )) }
                </div>
                <InView as='div' className={css.miniCardContainer} data-testid='work-minicards' onChange={handleIntersectionChange} triggerOnce threshold={0.5}>
                    { work.other.map((project, idx) => (
                        <MiniCard key={idx} data={project} />
                    )) }
                </InView>
            </div>
        </>
    );
};

export async function workLoader({ request: { url } }) {
    try {
        const locale = getLocale(url);
        const data = await Promise.all([
            getProjects('work', locale),
            getOtherProjects('work', locale)
        ]);
        const work = { main: data[0], other: data[1] };
        return work;
    } catch(error) { 
        throw new Response(JSON.stringify({ location: 'workLoader', error }), { status: 500, statusText: 'Internal Server Error' });
    }
};