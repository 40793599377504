import sanityClient from '@sanity/client';

function createClient() {
    return sanityClient({
        projectId: process.env.REACT_APP_SANITY_PID,
        dataset: process.env.REACT_APP_SANITY_DATASET,
        apiVersion: '2022-11-27',
        useCdn: true
    });
};

export async function getProjectCount() {
    const client = createClient();
    const query = `{ 'count': count(*[_type in ['work', 'project']]) }`;
    return client.fetch(query).then(({ count }) => count);
};

export async function getProject(projectSlug, locale = 'en') {
    const client = createClient();
    const query = `
        *[_type in ['project', 'work'] && slug.current == $projectSlug]
        {
            name, 
            spotlight {
                'desktop': desktop[].asset->url,
                'mobile': mobile[].asset->url
            },
            stack[]-> {
                name,
                'icon': icon.asset->url
            }, 
            demo, source,
            'abilities': abilities[$locale],
            'longDesc': description.long[$locale],
            'shortDesc': description.short[$locale],
            'isWork': _type == 'work'
        }
    `;
    const params = { projectSlug, locale };
    return client.fetch(query, params).then(res => res[0]);
};

export async function getRandomFeatured() {
    const client = createClient();

    const query = `
        *[_type in ['project', 'work'] && count(spotlight.desktop) > 1 && name != 'Portfolio v1']
        {
            'slug': slug.current,
            'screens': spotlight.desktop[].asset->url
        }
    `;
    return client.fetch(query).then(projects => projects
        .sort(() => 0.5 - Math.random())
        .slice(0, 3)
    );
};

export async function getProjects(type = 'project', locale = 'en') {
    const client = createClient();

    const query = `
        *[_type == $type]
        | order(orderRank)
        {
            name, tags,
            'slug': slug.current,
            'logo': logo.asset->url,
            thumbnail {
                'url': asset->url,
                'bg': asset->metadata.palette.darkVibrant.background
            },
            'shortDesc': description.short[$locale]
        }
    `;
    const params = { type, locale };
    return client.fetch(query, params);
};

export async function getOtherProjects(type = 'project', locale = 'en') {
    const client = createClient();
    type = `other${type[0].toUpperCase() + type.substring(1)}`;

    const query = `
        *[_type == $type]
        | order(orderRank)
        {
            'name': name[$locale], 
            tags, demo, source,
            'slug': slug.current,
            description {
                'long': long[$locale],
                'short': short[$locale]
            }
        }
    `;
    const params = { type, locale };
    return client.fetch(query, params);
};