import { Link, useLoaderData, useLocation, useRouteError } from 'react-router-dom';
import { Button, Tooltip, useMediaQuery } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { InView } from 'react-intersection-observer';
import { useRef } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import css from './styles/ProjectOverview.module.css';
import { getProject } from '../lib/sanity';
import { getLocale } from '../hooks/useLocale';
import DecoratedText from '../components/DecoratedText';
import DesktopImg from '../assets/images/desktop.webp';
import MobileImg from '../assets/images/mobile.webp';
import Laptop from '../components/Laptop';
import Mobile from '../components/Mobile';
import Error404 from './Error404';
import Error500 from './Error500';
import useImagePreloader from '../hooks/useImagePreloader';

const preloadSrcList = [ DesktopImg, MobileImg ];

export default function ProjectOverview() {
    const imagesPreloaded = useImagePreloader(preloadSrcList);
    const matchesDesktop = useMediaQuery('(min-width: 800px)');
    const isMobile = useMediaQuery('(hover: none)');
    const project = useLoaderData();
    const { pathname } = useLocation();
    const descriptionRef = useRef(null);

    const backStart = pathname.startsWith('/en') ? '/en' : pathname.startsWith('/es') ? '/es' : '';
    const backLink = `${backStart}${project.isWork ? '/work' : '/projects'}`;

    const handleIntersectionChange = (inView, entry) => {
        if (inView) entry.target.classList.add(css.animation);
    };

    const handleTechIntersection = (inView, entry, idx) => {
        if (!inView) return;
        entry.target.classList.add(css.animation);
        entry.target.style.animationDelay = `${(idx + 1) * 0.08 + 0.1}s`;
        entry.target.addEventListener('animationend', () => {
            entry.target.classList.remove(css.animation);
            entry.target.style.animationDelay = null;
            entry.target.style.opacity = 1;
        }, { once: true });
    };

    const handleAbilitiesIntersection = (inView, entry) => {
        if (!inView) return;
        entry.target.classList.add(css.animation);
        descriptionRef.current.classList.add(css.animation);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Emilio Popovits Blake: {project.name}</title>
                <meta property='og:title' content={`Emilio Popovits Blake: ${project.name}`} />
                <meta name='description' content={project.shortDesc[0].children[0].text.split('.')[0] + '.'} />
                <meta property='og:description' content={project.shortDesc[0].children[0].text.split('.')[0] + '.'} />
            </Helmet>
            
            <div className={css.wrapper}>
                <div className={`${css.head} ${css.animation}`}>
                    { !matchesDesktop && <Link className={css.back} to={backLink} data-testid='projectoverview-back'><ArrowBackIcon sx={{ fontSize: '2em' }} /></Link> }
                    <h1 className={css.projectName} data-testid='projectoverview-header'><DecoratedText>{project.name}</DecoratedText></h1>
                </div>
                <div className={`${css.spotlightContainer} ${css.animation}`} data-testid='projectoverview-spotlight'>
                    { project.spotlight?.desktop && <Laptop screens={project.spotlight.desktop} id={css.desktopSpotlight} /> }
                    { project.spotlight?.mobile && <Mobile screens={project.spotlight.mobile} id={css.mobileSpotlight} /> }
                </div>
                { imagesPreloaded ? (
                    <div className={css.content}>
                        <InView as='div' className={css.stackContainer} onChange={handleIntersectionChange} triggerOnce threshold={!isMobile ? 0.5 : 0}>
                            <h2 className={css.stackHeader}><DecoratedText>Stack</DecoratedText></h2>
                            <div className={css.stackItems} data-testid='projectoverview-stack'>
                                { project.stack.map((tech, idx) => (
                                    <InView as={Tooltip} key={idx} title={tech.name} onChange={(inView, entry) => handleTechIntersection(inView, entry, idx)} triggerOnce threshold={0.8}>
                                        <img 
                                            className={css.stackTech}
                                            src={tech.icon} 
                                            alt={tech.name} 
                                        />
                                    </InView>
                                )) }
                            </div>
                        </InView>
                        <InView className={css.abilitiesContainer} onChange={handleAbilitiesIntersection} triggerOnce threshold={0.2}>
                            <h2 className={css.abilitiesHeader} data-testid='projectoverview-abilitiesheader'><DecoratedText><FormattedMessage id='projectoverview-abilities' /></DecoratedText></h2>
                            <ul className={css.abilities} data-testid='projectoverview-abilities'>
                                { project.abilities.map((ability, idx) => (
                                    <li key={idx}>{ability}</li>
                                )) }
                            </ul>
                            <div className={css.abilitiesLinks}>
                                { Boolean(project.demo) && 
                                    <Button 
                                        sx={{ bgcolor: 'var(--highlight-color)', mt: '2em', mb: '2em' }} 
                                        variant='contained'
                                        data-testid='projectoverview-demo'
                                    >
                                        <a href={project.demo} target='_blank' rel='noreferrer'><FormattedMessage id='projectoverview-demo' /></a>
                                    </Button>
                                }
                                { Boolean(project.source) &&
                                    <Button 
                                        sx={{ bgcolor: 'var(--highlight-color)', mt: '2em', mb: '2em' }} 
                                        variant='contained'
                                        data-testid='projectoverview-source'
                                    >
                                        <a href={project.source} target='_blank' rel='noreferrer'><FormattedMessage id='projectoverview-source' /></a>
                                    </Button>
                                }
                            </div>
                        </InView>
                        <div ref={descriptionRef} className={css.description} data-testid='projectoverview-description'>
                            <PortableText value={project.longDesc} />
                        </div>
                    </div>
                ) : ( <div style={{ height: '100vh' }}></div> )}
            </div>
        </>
    );
};

export async function projectOverviewLoader({ request: { url }, params: { projectSlug } }) {
    const locale = getLocale(url);
    let project;
    
    try { project = await getProject(encodeURIComponent(projectSlug), locale); } 
    catch(error) { throw new Response(JSON.stringify({ location: 'projectOverviewLoader', error }), { status: 500, statusText: 'Internal Server Error' }); }

    if (project === undefined) throw new Response('Project not found', { status: 404, statusText: 'Not Found' });
    return project;
};

export function ProjectOverviewError() {
    const error = useRouteError();

    if (error.status === 404) return <Error404 />;
    return <Error500 />;
};