import { Lambda, Credentials } from 'aws-sdk';

const CREDENTIALS = new Credentials({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
});

const LAMBDA = new Lambda({
    credentials: CREDENTIALS,
    region: 'us-east-1'
});

export function validateContactMessageAndSend(message) {
    return LAMBDA.invoke({
        FunctionName: 'portfolio-send-contact-message',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(message)
    }).promise().then(({ Payload }) => JSON.parse(Payload))
        .then(({ statusCode, body }) => ({ statusCode, body }));
};