import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function LazyLoadCarouselImage({ src, alt, loading, ...props }) {
    const [imageSrc, setImageSrc] = useState(loading || null);
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(_ => {
        if (inView) setImageSrc(src);
    }, [inView, src]);

    return <img ref={ref} src={imageSrc} alt={alt} {...props} />;
};