import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import Layout from '../layouts/Layout';
import Home, { homeLoader } from './Home';
import Work, { workLoader } from './Work';
import Projects, { projectsLoader } from './Projects';
import ProjectOverview, { projectOverviewLoader, ProjectOverviewError } from './ProjectOverview';
import Contact from './Contact';
import Error404 from './Error404';
import Error500 from './Error500';

export const routerConfig = createRoutesFromElements(
    <>
        <Route path='/' element={<Layout />} errorElement={<Error500 />}>
            <Route index element={<Home />} loader={homeLoader} errorElement={<Error500 />} />
            <Route path='work' element={<Work />} loader={workLoader} errorElement={<Error500 />} />
            <Route path='projects' element={<Projects />} loader={projectsLoader} errorElement={<Error500 />} />
            <Route path='portfolio/:projectSlug' element={<ProjectOverview />} loader={projectOverviewLoader} errorElement={<ProjectOverviewError />} />
            <Route path='contact' element={<Contact />} />
            <Route path='*' element={<Error404 />} />
        </Route>
        <Route path='/en' element={<Layout />} errorElement={<Error500 />}>
            <Route index element={<Home />} loader={homeLoader} errorElement={<Error500 />} />
            <Route path='work' element={<Work />} loader={workLoader} errorElement={<Error500 />} />
            <Route path='projects' element={<Projects />} loader={projectsLoader} errorElement={<Error500 />} />
            <Route path='portfolio/:projectSlug' element={<ProjectOverview />} loader={projectOverviewLoader} errorElement={<ProjectOverviewError />} />
            <Route path='contact' element={<Contact />} />
            <Route path='*' element={<Error404 />} />
        </Route>
        <Route path='/es' element={<Layout />} errorElement={<Error500 />}>
            <Route index element={<Home />} loader={homeLoader} errorElement={<Error500 />} />
            <Route path='work' element={<Work />} loader={workLoader} errorElement={<Error500 />} />
            <Route path='projects' element={<Projects />} loader={projectsLoader} errorElement={<Error500 />} />
            <Route path='portfolio/:projectSlug' element={<ProjectOverview />} loader={projectOverviewLoader} errorElement={<ProjectOverviewError />} />
            <Route path='contact' element={<Contact />} />
            <Route path='*' element={<Error404 />} />
        </Route>
    </>
);

const BrowserRouter = createBrowserRouter(routerConfig);

export default function Router() {
    return <RouterProvider router={BrowserRouter} />
};