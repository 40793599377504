import { useEffect, useState } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import css from './styles/Layout.module.css';
import Nav from './Nav';
import useLocale from '../hooks/useLocale';
import en from '../lang/en.json';
import es from '../lang/es.json';
import Footer from './Footer';

export default function Layout() {
    const locale = useLocale();

    const [localeData, setLocaleData] = useState(en);

    useEffect(() => {
        if (locale === 'es') return setLocaleData(es);
        return setLocaleData(en);
    }, [locale]);

    return (
        <IntlProvider locale={locale} defaultLocale='en' messages={localeData}>
            <main className={css.wrapper} data-testid='layout-wrapper'>
                <Nav />
                <Outlet />
                <ScrollRestoration />
                <Footer />
            </main>
        </IntlProvider>
    );
};