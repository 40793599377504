import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import DecoratedText from '../components/DecoratedText';
import css from './styles/Error404.module.css';

export default function Error404() {
    const navigate = useNavigate();
    const intl = useIntl();

    const onBackClick = () => navigate(-1);
    const onHomeClick = () => navigate('/');

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Emilio Popovits Blake: 404</title>
                <meta property='og:title' content='Emilio Popovits Blake: 404' />
                <meta name='description' content={intl.formatMessage({ id: '404-description' })} />
                <meta property='og:description' content={intl.formatMessage({ id: '404-description' })} />
            </Helmet>

            <div className={css.wrapper}>
                <h1 data-testid='404-header'><DecoratedText><FormattedMessage id='404-header' /></DecoratedText></h1>
                <p className={css.sadFace}>);</p>
                <div className={css.textWrapper}>
                    <p><FormattedMessage id='404-message' /></p>
                    <DecoratedText className={css.backLink} onClick={onBackClick}><FormattedMessage id='404-back' /></DecoratedText>
                    <p className={css.or}><FormattedMessage id='404-or' /></p>
                    <DecoratedText className={css.backLink} onClick={onHomeClick}><FormattedMessage id='404-home' /></DecoratedText>
                </div>
            </div>
        </>
    );
};