import { useRef, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';

import css from './styles/Contact.module.css';
import { validateContactMessageAndSend } from '../lib/aws';
import DecoratedText from '../components/DecoratedText';
import * as Validator from '../utils/contactFormValidator';
import ContactFormInput from '../components/ContactFormInput';
import useLocale from '../hooks/useLocale';


export default function Contact() {
    const reCaptchaRef = useRef(null);
    const intl = useIntl();
    const locale = useLocale();

    const firstNameState = useState('');
    const firstNameErrorState = useState('');
    
    const lastNameState = useState('');
    const lastNameErrorState = useState('');
    
    const emailState = useState('');
    const emailErrorState = useState('');
    
    const messageState = useState('');
    const messageErrorState = useState('');

    const [serverError, setServerError] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [sending, setSending] = useState(false);

    const onClickSend = async () => {
        setShowSuccess(false);

        const [firstNameError, setFirstNameError] = firstNameErrorState;
        const [lastNameError, setLastNameError] = lastNameErrorState;
        const [emailError, setEmailError] = emailErrorState;
        const [messageError, setMessageError] = messageErrorState;

        const validationError = Boolean(firstNameError) || Boolean(lastNameError) || Boolean(emailError) || Boolean(messageError);
        if (validationError) return;

        const captchaToken = reCaptchaRef.current.getValue();
        if (!Boolean(captchaToken)) return setServerError(intl.formatMessage({ id: 'contact-error-recaptcha' }));

        setServerError('');

        const [firstName, setFirstName] = firstNameState;
        const [lastName, setLastName] = lastNameState;
        const [email, setEmail] = emailState;
        const [message, setMessage] = messageState;

        const contactMessage = {
            _type: 'message',
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            email: email.trim(),
            message: message,
            token: captchaToken
        };

        setSending(true);
        let response;
        try { response = await validateContactMessageAndSend(contactMessage); }
        catch(error) {
            if (['development', 'test'].includes(process.env.NODE_ENV)) console.error('ContactError: ', error);
            setServerError(intl.formatMessage({ id: 'contact-error-problem' }));
            setSending(false);
            reCaptchaRef.current.reset();
            return;
        }
        setSending(false);
        reCaptchaRef.current.reset();

        const { statusCode, body } = response;

        if (statusCode === 200) {
            setFirstName('');
            setLastName('');
            setEmail('');
            setMessage('');
            setShowSuccess(true);
            return;
        }

        // Validation error
        if (statusCode === 422) {
            const errors = JSON.parse(body);
            try {
                const validationErrors = parseValidationErrors(errors, locale);
                for (const [key, value] of Object.entries(validationErrors)) {
                    if (key === '_type') console.error(value);
                    if (key === 'firstName') setFirstNameError(value);
                    if (key === 'lastName') setLastNameError(value);
                    if (key === 'email') setEmailError(value);
                    if (key === 'message') setMessageError(value);
                }
            } catch (error) {
                console.error(error);
                setServerError(intl.formatMessage({ id: 'contact-error-problem' }));
            } finally {
                return;
            }
        }

        // Sanity error
        if (statusCode === 500) return setServerError(body);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{intl.formatMessage({ id: 'contact-title' })}</title>
                <meta property='og:title' content={intl.formatMessage({ id: 'contact-title' })} />
                <meta name='description' content={intl.formatMessage({ id: 'contact-description' })} />
                <meta property='og:description' content={intl.formatMessage({ id: 'contact-description' })} />
            </Helmet>

            <div className={css.wrapper}>
                <h1 className={`${css.header} ${css.animation}`} data-testid='contact-header'><DecoratedText><FormattedMessage id='contact-header' /></DecoratedText></h1>
                { Boolean(serverError) && 
                    <div className={css.serverErrorContainer} data-testid='contact-error'>
                        <p>{serverError}</p>
                    </div>
                }
                { Boolean(showSuccess) &&
                    <div className={css.successMessageContainer} data-testid='contact-success'>
                        <p><FormattedMessage id='contact-success' /></p>
                    </div>
                }
                <form className={`${css.form} ${css.animation}`}>
                    <div className={`${css.nameContainer} ${css.animation}`}>
                        <ContactFormInput
                            state={firstNameState}
                            errorState={firstNameErrorState}
                            label={intl.formatMessage({ id: 'contact-firstname' })}
                            validator={Validator.validateFirstName}
                            alt={intl.formatMessage({ id: 'contact-firstname-alt' })}
                        />
                        <ContactFormInput
                            state={lastNameState}
                            errorState={lastNameErrorState}
                            label={intl.formatMessage({ id: 'contact-lastname' })}
                            validator={Validator.validateLastName}
                            alt={intl.formatMessage({ id: 'contact-lastname-alt' })}
                        />
                    </div>
                    <ContactFormInput
                        sx={{ mb: '2em' }}
                        fullWidth
                        state={emailState}
                        errorState={emailErrorState}
                        label={intl.formatMessage({ id: 'contact-email' })}
                        validator={Validator.validateEmail}
                        alt={intl.formatMessage({ id: 'contact-email-alt' })}
                        id={css.emailInput}
                    />
                    <ContactFormInput
                        fullWidth multiline
                        minRows={5}
                        state={messageState}
                        errorState={messageErrorState}
                        label={intl.formatMessage({ id: 'contact-message' })}
                        validator={Validator.validateMessage}
                        alt={intl.formatMessage({ id: 'contact-message-alt' })}
                    />
                    <Button 
                        sx={{ bgcolor: 'var(--highlight-color)', mt: '2em', mb: '2em' }}
                        variant='contained' 
                        onClick={onClickSend}
                        data-testid='contact-send-button'
                    >
                        { !sending ? intl.formatMessage({ id: 'contact-send' }) : <CircularProgress size='1em' color='inherit' />}
                    </Button>
                    <ReCAPTCHA hl={locale} data-testid='contact-recaptcha' sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={reCaptchaRef} theme='dark' />
                </form>
            </div>
        </>
    );
};

export function parseValidationErrors(errors, locale = 'en') {
    const validationErrors = { 
        _type: '', firstName: '', lastName: '', email: '', message: ''
    };

    const regexes = {
        _type: /document/i, firstName: /first name/i, lastName: /last name/i,
        email: /email/i, message: /message/i
    };

    const localeEs = {
        'First name is required': 'Tu nombre no puede estar vacío',
        'First name must be between 3 and 50 characters long': 'Tu nombre tiene que ser entre 3 y 50 caracteres de largo',
        'Last name is required': 'Tu apellido no puede estar vacío',
        'Last name must be between 3 and 80 characters long': 'Tu apellido tiene que ser entre 3 y 80 caracteres de largo',
        'Email is required': 'Tu correo no puede estar vacío',
        'Email is invalid': 'Tu correo es inválido',
        'Message is required': 'Tu mensaje no puede estar vacío',
        'Message must be at least 10 characters long': 'Tu mensaje tiene que ser de por lo menos 10 caracteres'
    };

    for (const error of errors) {
        if (error.length > 200) {
            if (locale === 'es') throw new Error('Se encontró un error de validación no-válido.');
            throw new Error('Encountered a non-valid validation error.');
        }

        for (const [key, value] of Object.entries(regexes)) {
            if (!value.test(error)) continue;
            validationErrors[key] = error;
            continue;
        }
    }

    for (const [key, value] of Object.entries(validationErrors))
        if (value === '') delete validationErrors[key];

    if (locale === 'es') {
        const validationErrorsEs = {};
        for (const [key, value] of Object.entries(validationErrors))
            validationErrorsEs[key] = localeEs[value];
        return validationErrorsEs;
    }

    return validationErrors;
};