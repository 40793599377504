import { useCallback, useEffect, useRef } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { PortableText } from '@portabletext/react';
import CloseIcon from '@mui/icons-material/Close';

import css from './styles/Modal.module.css';
import DecoratedText from './DecoratedText';

export default function Modal({ visible, onClose, data }) {
    const backgroundRef = useRef(null);
    const handleEscKey = useCallback(e => {
        if (e.key === 'Escape') onClose();
    }, [onClose]);

    useEffect(_ => {
        if (visible) document.addEventListener('keydown', handleEscKey);
        return () => { document.removeEventListener('keydown', handleEscKey); }
    }, [visible, handleEscKey]);

    const handleClickAway = ({ target }) => {
        if (Array.from(target.classList)[0]?.includes('background')) onClose();
    };

    return (
        <RemoveScroll 
            ref={backgroundRef}
            enabled={visible} 
            className={css.background} 
            style={{ display: visible ? 'flex' : null }}
            onClick={handleClickAway}
            data-testid='modal-background'
        >
                <div className={css.modal} data-testid='modal-wrapper'>
                    <div className={css.headerContainer}>
                        <CloseIcon 
                            onClick={onClose}
                            sx={{ position: 'absolute', left: '0.5em', fontSize: '1em', cursor: 'pointer', color: 'var(--highlight-color)' }}
                            data-testid='modal-close'
                        />
                        <DecoratedText className={css.header}>{data.name}</DecoratedText>
                    </div>
                    <div className={css.bodyContainer} data-testid='modal-body'>
                        <PortableText value={data.description.long} />
                        <div className={css.buttonsContainer} data-testid='modal-buttons'>
                            { Boolean(data.demo) && 
                                <Button 
                                    sx={{ bgcolor: 'var(--highlight-color)', mt: '2em' }} 
                                    variant='contained'
                                    data-testid='modal-demo'
                                >
                                    <a href={data.demo} target='_blank' rel='noreferrer'><FormattedMessage id='projectoverview-demo' /></a>
                                </Button>
                            }
                            { Boolean(data.source) &&
                                <Button 
                                    sx={{ bgcolor: 'var(--highlight-color)', mt: '2em' }} 
                                    variant='contained'
                                    data-testid='modal-source'
                                >
                                    <a href={data.source} target='_blank' rel='noreferrer'><FormattedMessage id='projectoverview-source' /></a>
                                </Button>
                            }
                        </div>
                    </div>
                </div>
        </RemoveScroll>
    );
};