import { styled, TextField } from '@mui/material';

import css from './styles/ContactFormInput.module.css';
import DecoratedText from './DecoratedText';
import useLocale from '../hooks/useLocale';

const ContactTextField = styled(TextField)({
    '& label': {
        color: '#fff',
        '&.Mui-focused': {
            color: '#fff',
            '&.Mui-error': { color: '#f00' }
        }
    },
    '& .MuiOutlinedInput-root': {
        '& input, textarea': { color: '#fff' },
        '& fieldset': { borderColor: 'var(--highlight-color)' },
        '&:hover fieldset': {
            borderColor: 'var(--highlight-color)',
            borderWidth: 2
        },
        '&.Mui-error fieldset': { borderColor: 'red' },
    },
    '& p.Mui-error': { fontSize: '0.8em', maxWidth: '25ch' }
});

const localeEs = {
    'First name is required': 'Tu nombre no puede estar vacío',
    'First name must be between 3 and 50 characters long': 'Tu nombre tiene que ser entre 3 y 50 caracteres de largo',
    'Last name is required': 'Tu apellido no puede estar vacío',
    'Last name must be between 3 and 80 characters long': 'Tu apellido tiene que ser entre 3 y 80 caracteres de largo',
    'Email is required': 'Tu correo no puede estar vacío',
    'Email is invalid': 'Tu correo es inválido',
    'Message is required': 'Tu mensaje no puede estar vacío',
    'Message must be at least 10 characters long': 'Tu mensaje tiene que ser de por lo menos 10 caracteres'
};

export default function ContactFormInput({ state, errorState, label, validator, ...props }) {
    const locale = useLocale();

    const [value, setValue] = state;
    const [inputError, setInputError] = errorState;

    const onChangeInput = e => {
        setInputError('');
        setValue(e.target.value);
    };

    const onBlurInput = e => {
        const validation = validator(e.target.value);
        if (validation === true) return;
        if (locale === 'es') return setInputError(localeEs[validation])
        setInputError(validation);
    };

    return (
        <ContactTextField 
            variant='outlined' type='text'
            label={<Label>{label}</Label>} 
            error={Boolean(inputError)}
            helperText={inputError}
            value={value}
            onChange={onChangeInput}
            onBlur={onBlurInput}
            data-testid='contact-form-input'
            {...props}
        />
    );
};

function Label({ children }) {
    return <DecoratedText className={css.label}>{children}</DecoratedText>
};