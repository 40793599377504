import { useNavigate, useRouteError } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import DecoratedText from '../components/DecoratedText';
import css from './styles/Error404.module.css';

export default function Error500() {
    const error = useRouteError();
    if (['development', 'test'].includes(process.env.NODE_ENV) && error) console.error(error.data ? JSON.parse(error?.data) : error);

    const navigate = useNavigate();
    const intl = useIntl();

    const onHomeClick = () => navigate('/');

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Emilio Popovits Blake: 500</title>
                <meta property='og:title' content='Emilio Popovits Blake: 500' />
                <meta name='description' content={intl.formatMessage({ id: '500-description' })} />
                <meta property='og:description' content={intl.formatMessage({ id: '500-description' })} />
            </Helmet>
            
            <div className={css.wrapper}>
                <h1 data-testid='500-header'><DecoratedText><FormattedMessage id='500-header' /></DecoratedText></h1>
                <p className={css.sadFace}>);</p>
                <div className={css.textWrapper}>
                    <p><FormattedMessage id='500-message' /></p>
                    <DecoratedText className={css.backLink} onClick={onHomeClick}><FormattedMessage id='500-home' /></DecoratedText>
                </div>
            </div>
        </>
    );
};