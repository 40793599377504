import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useLocale() {
    const { pathname } = useLocation();

    const [locale, setLocale] = useState('en');

    useEffect(() => {
        if (pathname.startsWith('/es')) return setLocale('es');
        return setLocale('en');
    }, [pathname]);

    return locale;
};

export function getLocale(url) {
    const pathname = (new URL(url)).pathname;

    if (pathname.startsWith('/es')) return 'es';
    return 'en';
};