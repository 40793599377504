import validator from 'validator';

export function validateFirstName(first_name) {
    const exists = first_name !== undefined && first_name !== null && !validator.isEmpty(validator.trim(first_name));
    if (!exists) return 'First name is required';

    first_name = validator.trim(first_name);
    first_name = validator.blacklist(first_name, '<|>|/');

    const isCorrectLength = validator.isLength(first_name, { min: 3, max: 50 });
    if (!isCorrectLength) return 'First name must be between 3 and 50 characters long';

    return true;
};

export function validateLastName(last_name) {
    const exists = last_name !== undefined && last_name !== null && !validator.isEmpty(validator.trim(last_name));
    if (!exists) return 'Last name is required';

    last_name = validator.trim(last_name);
    last_name = validator.blacklist(last_name, '<|>|/');
    
    const isCorrectLength = validator.isLength(last_name, { min: 3, max: 80 });
    if (!isCorrectLength) return 'Last name must be between 3 and 80 characters long';

    return true;
};

export function validateEmail(email) {
    const exists = email !== undefined && email !== null && !validator.isEmpty(validator.trim(email));
    if (!exists) return 'Email is required';
    
    email = validator.trim(email);
    email = validator.escape(email);

    const isEmail = validator.isEmail(email, { domain_specific_validation: true });
    if (!isEmail) return 'Email is invalid';

    return true;
};

export function validateMessage(message) {
    const exists = message !== undefined && message !== null && !validator.isEmpty(message);
    if (!exists) return 'Message is required';
    
    message = validator.escape(message);

    const isMinLength = validator.isLength(message, { min: 10 });
    if (!isMinLength) return 'Message must be at least 10 characters long';

    return true;
};